import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hollow Rocks 3x:45`}</p>
    <p>{`Superman Extensions 3×10/side`}</p>
    <p>{`then,`}</p>
    <p>{`Deadlifts 5×2\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 11.2`}</strong></p>
    <p>{`15:00 AMRAP:`}</p>
    <p>{`9-Deadlifts (155/100)`}</p>
    <p>{`12-Hand Release Pushups`}</p>
    <p>{`15-Box Jumps (24/20″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      